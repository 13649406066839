<script setup>
import { ref } from "vue";
import { HelpIcon, ChevronDownIcon } from "vue-tabler-icons";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from 'pinia'

const userStore = useUserStore();
const router = useRouter();
const customizer = useCustomizerStore()

const { selectedWorkspace } = storeToRefs(userStore);
const window = ref(selectedWorkspace.value.role['workspace_settings'] ? 'overview' : 'changeWorkspace')
const loadingWorkspaces = ref(false)
const workspaceMenu = ref(false)
const workspaceItems = [
  { title: 'Workspace wechseln', method: () => { window.value = 'changeWorkspace' }, icon: 'mdi-swap-horizontal' },
  { type: 'divider' },
  { title: 'Einstellungen', method: () => { router.push({ path: '/workspace/settings' }); workspaceMenu.value = false; }, icon: 'mdi-cog' },
]

const changeWorkspace = (item) => {
  userStore.setWorkspace(item)
  workspaceMenu.value = false
  if (customizer.featureFlags['workspace_settings'])
    window.value = 'overview'
}

</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- mega menu DD -->
  <!-- ---------------------------------------------- -->
  <v-menu v-model="workspaceMenu" :close-on-content-click="false" v-if="selectedWorkspace">
    <template v-slot:activator="{ props }">
      <v-btn class="custom-hover-primary d-flex flex-shrink-1" rounded="sm" variant="text" v-bind="props">
        <h5 class="text-textPrimary text-h5">{{ selectedWorkspace?.name }}</h5>
        <ChevronDownIcon size="18" class="mt-1 ml-1" />
      </v-btn>
    </template>
    <v-sheet elevation="10" rounded="md" class="px-2">
      <v-window v-model="window">
        <v-window-item value="overview">
          <v-list>
            <template v-for="(item, i) in workspaceItems">
              <v-divider class="border-opacity-100" v-if="item.type === 'divider'" :key="i + '_divider'"></v-divider>
              <v-list-item v-else depressed :key="i + '_item'" outlined @click="item.method" class="text-none">
                <template v-slot:prepend>
                  <v-icon :icon="item.icon" color="info"></v-icon>
                </template>
                <v-list-item-title v-text="item.title" class="text-h6"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-window-item>
        <v-window-item value="changeWorkspace">
          <v-list>
            <template v-if="customizer.featureFlags['workspace_settings']">
              <v-list-item depressed outlined @click="window = 'overview'" class="text-none">
                <template v-slot:prepend>
                  <v-icon icon="mdi-keyboard-backspace" color="info"></v-icon>
                </template>
                <v-list-item-title class="text-h6">Zurück</v-list-item-title>
              </v-list-item>
              <v-divider class="border-opacity-100" />
            </template>
            <v-list-item v-for="(item, i)  in userStore.getWorkspaces" rounded :key="i" depressed outlined
              @click="changeWorkspace(item)" class="text-none">
              <template v-slot:prepend>
                <v-icon v-if="item.id === userStore.selectedWorkspace.id" color="success">
                  <CircleCheckFilledIcon size="24" />
                </v-icon>
                <v-icon v-else>
                  <CircleDashedIcon size="24" />
                </v-icon>
              </template>
              <h6 class="text-textSecondary text-h6">{{ item?.name }}</h6>
              <!-- <template v-slot:append>
                <v-btn class="ml-4" variant="flat" size="small">
                  <v-icon :color="item.default ? 'yellow' : 'gray'">{{item.default ? 'mdi-start' : 'mdi-star-outline'}}</v-icon>
                </v-btn>
              </template> -->
            </v-list-item>
          </v-list>
        </v-window-item>
      </v-window>

    </v-sheet>
  </v-menu>
</template>
<style scoped lang="scss">
h5,
:deep(.v-btn__content) {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
