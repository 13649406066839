<script setup lang="ts">
import {StatusBar, Style} from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import {useCustomizerStore} from "@/stores/customizer";
import {useUserStore} from "@/stores/user";
import LogoLoadingAnimation from "~/components/shared/LogoLoadingAnimation.vue";
import customDark from "~/assets/themes/eChartCustomDark.json";
import {registerTheme} from "echarts";

registerTheme('custom-dark', customDark);

const customizer = useCustomizerStore();
const user = useUserStore();
const customizerStore = useCustomizerStore()
const {status} = useAuth();

const title = ref("Integrahub - Business Value Centralizer");
const isLoading = ref(true)
useHead({
  meta: [{content: title}],
  titleTemplate: (titleChunk: string) => {
    return titleChunk
        ? `${titleChunk}`
        : "Integrahub - Business Value Centralizer";
  },
});
useSeoMeta({
  robots: {
    nofollow: true,
    noindex: true,
    notranslate: true,
    noimageindex: true,
    noarchive: true,
    nosnippet: true,
    nositelinkssearchbox: true,
  }
})

const setStatusBarStyleLight = async () => {
  await StatusBar.setBackgroundColor({color: '#000'})
  await StatusBar.setStyle({style: Style.Light});
};

if (process.server) {
  await user.fetchUser()
  await customizerStore.fetchFeatureFlags(user?.user?.id)
} else {
  new Promise(resolve => setTimeout(resolve, 500)).then(async () => {
    await user.fetchUser()
    await customizerStore.fetchFeatureFlags(user?.user?.id)
    if (Capacitor.isNative) {
      // Display content under transparent status bar (Android only)
      //StatusBar.setOverlaysWebView({ overlay: true });
      setStatusBarStyleLight()
      window.document.body.style.setProperty('position', 'fixed')
    }
    isLoading.value = false
  }).catch(e => console.log('e :>> ', e));
}


</script>

<template>
  <NuxtPwaManifest/>
  <template v-if="!isLoading && status !== 'loading'">

    <!-----RTL LAYOUT------->
    <v-locale-provider v-if="customizer.setRTLLayout" rtl>
      <v-app :theme="customizer.actTheme" :class="[
    customizer.actTheme,
    customizer.mini_sidebar ? 'mini-sidebar' : '',
    customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
    customizer.setBorderCard ? 'cardBordered' : '',
  ]">
        <!---Customizer location left side--->
        <!--             <v-navigation-drawer app temporary elevation="10" location="left" v-model="customizer.Customizer_drawer" width="320" class="left-customizer">-->
        <!--                <LcFullCustomizer/>-->
        <!--             </v-navigation-drawer>-->
        <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout"/>
        <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout"/>
        <LcFullHorizontalHeader v-if="customizer.setHorizontalLayout"/>
        <LcFullHorizontalSidebar v-if="customizer.setHorizontalLayout"/>
        <v-main>
          <v-container fluid class="page-wrapper pb-sm-15 pb-10">
            <div :class="customizer.boxed ? 'maxWidth' : ''">
              <NuxtPage/>
              <!--                        <v-btn-->
              <!--                            class="customizer-btn"-->
              <!--                            size="large"-->
              <!--                            icon-->
              <!--                            variant="flat"-->
              <!--                            color="primary"-->
              <!--                            @click.stop="customizer.SET_CUSTOMIZER_DRAWER(!customizer.Customizer_drawer)"-->
              <!--                        >-->
              <!--                            <SettingsIcon />-->
              <!--                        </v-btn>-->
            </div>
          </v-container>
        </v-main>
      </v-app>
    </v-locale-provider>

    <!-----LTR LAYOUT------->
    <v-locale-provider v-else>
      <v-app :theme="customizer.actTheme" :class="[
    customizer.actTheme,
    customizer.mini_sidebar ? 'mini-sidebar' : '',
    customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
    customizer.setBorderCard ? 'cardBordered' : '',
  ]">
        <!---Customizer location right side--->
        <!--             <v-navigation-drawer app temporary elevation="10" location="right" v-model="customizer.Customizer_drawer" width="320">-->
        <!--             <LcFullCustomizer/>-->
        <!--             </v-navigation-drawer>-->
        <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout"/>
        <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout"/>
        <LcFullHorizontalHeader v-if="customizer.setHorizontalLayout"/>
        <LcFullHorizontalSidebar v-if="customizer.setHorizontalLayout"/>
        <v-main>
          <v-container fluid class="page-wrapper pb-sm-15 pb-10">
            <div :class="customizer.boxed ? 'maxWidth' : ''">
              <NuxtPage/>
              <!--                        <v-btn-->
              <!--                            class="customizer-btn"-->
              <!--                            size="large"-->
              <!--                            icon-->
              <!--                            variant="flat"-->
              <!--                            color="primary"-->
              <!--                            @click.stop="customizer.SET_CUSTOMIZER_DRAWER(!customizer.Customizer_drawer)"-->
              <!--                        >-->
              <!--                            <SettingsIcon />-->
              <!--                        </v-btn>-->
            </div>
          </v-container>
        </v-main>
      </v-app>
    </v-locale-provider>
  </template>
  <div v-else style="height: 100dvh" class="d-flex justify-center align-center flex-column">
    <LogoLoadingAnimation/>
  </div>
</template>
<style scoped>

@media print{
    .v-main {
        padding: 0 !important;
    }
}   
</style>